import React, { useState, useEffect } from "react";
import Layout from "../components/layout";
import { FaWindowClose, FaHamburger, FaSyncAlt } from "react-icons/fa";

const BeerOlympics = ({ location }) => {
  const windowGlobal = typeof window !== "undefined" && window;
  const localStorageItems = { ...windowGlobal.localStorage };
  console.log("localStorageItems: ", localStorageItems);
  var localPlayers = localStorageItems.players
    ? windowGlobal.localStorage.getItem("players")
    : null;
  var localTeams = localStorageItems.teams
    ? windowGlobal.localStorage.getItem("teams")
    : null;
  var localExtraTeams = localStorageItems.extraTeams
    ? windowGlobal.localStorage.getItem("extraTeams")
    : null;
  var localSchedule = localStorageItems.schedule
    ? windowGlobal.localStorage.getItem("schedule")
    : null;
  console.log("localPlayers: ", JSON.parse(localPlayers));
  console.log("localTeams: ", JSON.parse(localTeams));
  console.log("localExtraTeams: ", JSON.parse(localExtraTeams));
  console.log("localSchedule: ", JSON.parse(localSchedule));
  const [switchTeamsPlayers, setSwitchTeamsPlayers] = useState(false);
  const [count, setCount] = useState(1);
  const [refresh, setRefresh] = useState(1);
  const [showMenu, setShowMenu] = useState(false);
  const [showGamesComponent, setShowGamesComponent] = useState(true);
  const [showStandings, setShowStandings] = useState(true);
  const [showBlindDraw, setShowBlindDraw] = useState(false);
  const [playerInput, setPlayerInput] = useState(null);
  const [teamInput, setTeamInput] = useState(null);
  const [generateTeams, setGenerateTeams] = useState(true);
  const [standings, setStandings] = useState([]);
  const teamColors = [
    "#ff0000",
    "#0083ff",
    "#ffe500",
    "#00fff6",
    "#ff9900",
    "#1000ff",
    "#966400",
    "#8c00ff",
    "#f200ff",
    "#ff0087",
    "#008296",
  ];
  const params = new URLSearchParams(location.search);
  const [games, setGames] = useState([
    "Bags",
    "Baseball",
    "KanJam",
    "Beersbie",
    "Spike Ball",
  ]);
  const [selectedGame, setSelectedGame] = useState("All");
  const [schedule, setSchedule] = useState(
    localSchedule ? JSON.parse(localSchedule) : []
  );
  const [teams, setTeams] = useState(localTeams ? JSON.parse(localTeams) : []);
  const [extraTeams, setExtraTeams] = useState(
    localExtraTeams ? JSON.parse(localExtraTeams) : []
  );
  const [players, setPlayers] = useState(
    localPlayers ? JSON.parse(localPlayers) : []
  );
  console.log("players: ", players);
  console.log("teams: ", teams);

  function GetTeamStats(team, type, matches) {
    let total = 0;
    const filteredMatches = matches.filter((item) => item.name === team);
    filteredMatches.forEach((match) => {
      if (type === "wins" && match.isWinner) ++total;
      if (type === "losses" && match.isLoser) ++total;
      if (type === "gamesPlayed" && (match.isWinner || match.isLoser)) ++total;
    });
    return total;
  }

  function GenerateStandings(scheduleType) {
    let allMatches = [];
    (scheduleType ? [...scheduleType] : [...schedule]).forEach((game) => {
      game.matchups.forEach((match) => {
        allMatches.push(match[0]);
        allMatches.push(match[1]);
      });
    });
    console.log("allMatches: ", allMatches);
    let teamStandings = teams.map((team) => {
      let newObj = {
        name: team,
        wins: GetTeamStats(team, "wins", allMatches),
        losses: GetTeamStats(team, "losses", allMatches),
        gamesPlayed: GetTeamStats(team, "gamesPlayed", allMatches),
      };
      return newObj;
    });
    console.log("teamStandings: ", teamStandings);
    setStandings(teamStandings);
    setCount(count + 1);
  }

  function addPlayer(name) {
    const playerArray = [...players, name];
    setPlayers(playerArray);
  }
  function addTeam(name) {
    const teamArray = [...teams, name];
    const teamExtraArray = [...extraTeams, name];
    windowGlobal.localStorage.setItem("teams", JSON.stringify(teamArray));
    windowGlobal.localStorage.setItem(
      "extraTeams",
      JSON.stringify(teamExtraArray)
    );
    setTeams(teamArray);
    setExtraTeams(teamExtraArray);
  }

  function removePlayer(index, removeAll) {
    if (removeAll) {
      setPlayers([]);
    } else {
      const playerArray = players.filter(
        (playerName, playerIndex) => playerIndex !== index
      );
      windowGlobal.localStorage.setItem("players", JSON.stringify(playerArray));
      setPlayers(playerArray);
    }
  }
  function removeTeam(index, removeAll) {
    if (removeAll) {
      setTeams([]);
    } else {
      const teamArray = teams.filter(
        (teamName, teamIndex) => teamIndex !== index
      );
      windowGlobal.localStorage.setItem("teams", JSON.stringify(teamArray));
      setTeams(teamArray);
    }
  }

  function GenerateMatchups(teams) {
    let matchupArray = [];
    let currentIndex = 0;
    teams.forEach((team, index) => {
      const arrayToIterate = teams.slice(currentIndex);
      arrayToIterate.forEach((item, index) => {
        if (index > 0) {
          matchupArray.push([team, item]);
        }
      });
      ++currentIndex;
    });
    return matchupArray;
  }

  function GenerateSchedule() {
    windowGlobal.localStorage.removeItem("schedule");
    const teamObjects = teams.map((team, index) => {
      let newObj = {
        id: index,
        name: team,
        isWinner: false,
        isLoser: false,
      };
      return newObj;
    });
    const newSchedule = games.map((game, index) => {
      let gameObj = {
        name: game,
        matchups: GenerateMatchups(teamObjects),
      };
      return gameObj;
    });
    console.log("newSchedule: ", newSchedule);
    windowGlobal.localStorage.setItem("schedule", JSON.stringify(newSchedule));
    setSchedule(newSchedule);
    setCount(count + 1);
    window.location.reload();
  }

  //   GenerateSchedule();
  const PlayerInput = () => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        addPlayer(playerInput);
        setPlayerInput("");
      }
    };

    return (
      <input
        key="players"
        type="text"
        autoFocus
        value={playerInput}
        onChange={(e) => setPlayerInput(e.target.value)}
        style={{
          padding: 10,
          border: "1px solid #d2d2d2",
          borderRadius: 7,
          marginRight: 10,
          fontSize: 18,
        }}
        onKeyDown={handleKeyDown}
      />
    );
  };
  const TeamInput = () => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        addTeam(teamInput);
        setTeamInput("");
      }
    };

    return (
      <input
        key="teams"
        type="text"
        autoFocus
        value={teamInput}
        onChange={(e) => setTeamInput(e.target.value)}
        style={{
          padding: 10,
          border: "1px solid #d2d2d2",
          borderRadius: 7,
          marginRight: 10,
          fontSize: 18,
        }}
        onKeyDown={handleKeyDown}
      />
    );
  };

  function shuffle(array) {
    setGenerateTeams(false);
    var currentIndex = array.length,
      temporaryValue,
      randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      var randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      var temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    console.log("array: ", array);
    const cache = params.get("cache");
    console.log("cache: ", cache);
    console.log("cache == '1': ", cache == "1");
    const newArr =
      cache && cache == "1"
        ? array.filter((name) => name !== "Phil" && name !== "Andrew")
        : array;
    console.log("newArr: ", newArr);
    const pairedTeams = [];
    for (let i = 0; i < newArr.length; i += 2) {
      pairedTeams.push(
        `${newArr[i]} ${
          newArr[i + 1] !== undefined ? `/ ${newArr[i + 1]}` : ""
        }`
      );
    }
    if (cache == "1") {
      pairedTeams.push("Phil / Andrew");
    }
    windowGlobal.localStorage.setItem("teams", JSON.stringify(pairedTeams));
    setTeams([...pairedTeams.reverse(), ...extraTeams]);
  }

  useEffect(() => {
    if (players.length > 0) {
      windowGlobal.localStorage.setItem("players", JSON.stringify(players));
    }
  }, [players]);

  useEffect(() => {
    console.log("schedule should be updating: ", schedule);
  }, [schedule]);

  function ClearTeams() {
    setGenerateTeams(true);
    setTeams([]);
  }

  useEffect(() => {
    if (refresh === 0) {
      setRefresh(1);
    }
  }, [refresh]);

  useEffect(() => {
    setRefresh(0);
  }, [count]);

  function UpdateMatchOutcome(
    match,
    selected,
    selectedIndex,
    gameIndex,
    matchIndex
  ) {
    setSelectedGame("All");
    // console.log("match: ", match);
    // console.log("matchIndex: ", matchIndex);
    // console.log("gameIndex: ", gameIndex);
    // console.log("selected: ", selected);
    // console.log("selectedIndex: ", selectedIndex);
    if (selected.isWinner) {
      match[selectedIndex].isWinner = false;
      match[selectedIndex].isLoser = false;
      if (selectedIndex === 0) {
        match[1].isWinner = false;
        match[1].isLoser = false;
      } else {
        match[0].isWinner = false;
        match[0].isLoser = false;
      }
    } else if (!selected.isWinner) {
      match[selectedIndex].isWinner = true;
      match[selectedIndex].isLoser = false;
      if (selectedIndex === 0) {
        match[1].isWinner = false;
        match[1].isLoser = true;
      } else {
        match[0].isWinner = false;
        match[0].isLoser = true;
      }
    }
    // console.log("match after update: ", match);
    let updatedSchedule = schedule;
    updatedSchedule[gameIndex].matchups[matchIndex] = [...match];
    // console.log("updatedSchedule: ", updatedSchedule);
    windowGlobal.localStorage.setItem(
      "schedule",
      JSON.stringify(updatedSchedule)
    );
    setSchedule(updatedSchedule);
    setCount(count + 1);
    GenerateStandings();
  }

  return (
    <Layout hideLayout={true}>
      <div
        style={{
          padding: 50,
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
          alignContent: "flex-start",
          justifyContent: "flex-start",
          paddingRight: 400,
          // border: "1px solid black",
        }}
      >
        {showBlindDraw ? (
          <>
            <div
              style={{
                marginTop: 15,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  style={{ display: "flex", flexDirection: "column", flex: 1 }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    {switchTeamsPlayers ? <TeamInput /> : <PlayerInput />}
                    <div
                      style={{
                        padding: 10,
                        textAlign: "center",
                        border: "1px solid #d2d2d2",
                        borderRadius: 7,
                      }}
                      onClick={() =>
                        switchTeamsPlayers
                          ? addTeam(teamInput)
                          : addPlayer(playerInput)
                      }
                    >
                      {switchTeamsPlayers ? "Add Team" : "Add Player"}
                    </div>
                    <FaSyncAlt
                      style={{ marginLeft: 10 }}
                      onClick={() => setSwitchTeamsPlayers(!switchTeamsPlayers)}
                    />
                  </div>
                  {players.length > 0 ? (
                    <div
                      style={{
                        marginTop: 10,
                        marginBottom: 15,
                        border: "1px solid #d2d2d2",
                        borderRadius: 7,
                        padding: 10,
                        textAlign: "center",
                        alignSelf: "flex-start",
                        backgroundColor: "#ea7575",
                        color: "#fff",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        removePlayer(null, true);
                        setTeams([]);
                      }}
                    >
                      Clear All Players
                    </div>
                  ) : null}
                  {players.map((player, index) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "flex-end",
                          padding: "10px 0",
                        }}
                      >
                        <span style={{ fontSize: 15, marginRight: 10 }}>
                          {index + 1}.
                        </span>
                        <span
                          style={{
                            color: "#333",
                            fontWeight: "600",
                          }}
                        >
                          {player}
                        </span>
                        <div
                          style={{ marginLeft: 10 }}
                          onClick={() => removePlayer(index)}
                        >
                          <FaWindowClose style={{ color: "#ea7575" }} />
                        </div>
                      </div>
                    );
                  })}
                </div>

                <div
                  style={{ display: "flex", flexDirection: "column", flex: 1 }}
                >
                  {players.length > 0 ? (
                    <div
                      style={{
                        marginBottom: 15,
                        border: "1px solid #d2d2d2",
                        borderRadius: 7,
                        padding: 10,
                        textAlign: "center",
                        alignSelf: "flex-start",
                        color: "#333",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        generateTeams || teams.length === 0
                          ? shuffle(players)
                          : ClearTeams()
                      }
                    >
                      {generateTeams || teams.length === 0
                        ? "Generate Teams"
                        : "Clear All Teams"}
                    </div>
                  ) : null}
                  {teams.length > 0 ? (
                    <div>
                      {teams.map((team, index) => {
                        return (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "flex-end",
                              padding: "10px 0",
                            }}
                            key={team}
                          >
                            <span
                              style={{
                                fontSize: 15,
                                marginRight: 10,
                              }}
                            >
                              {index + 1}.
                            </span>
                            <span
                              style={{
                                color: "#333",
                                fontWeight: "600",
                              }}
                            >
                              {team}
                            </span>
                            <div
                              style={{ marginLeft: 10 }}
                              onClick={() => removeTeam(index)}
                            >
                              <FaWindowClose style={{ color: "#ea7575" }} />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : null}
                </div>

                <div
                  style={{ display: "flex", flexDirection: "column", flex: 1 }}
                >
                  <div
                    style={{
                      marginBottom: 15,
                      border: "1px solid #d2d2d2",
                      borderRadius: 7,
                      padding: 10,
                      textAlign: "center",
                      alignSelf: "flex-start",
                      color: "#333",
                      cursor: "pointer",
                    }}
                    onClick={() => GenerateSchedule()}
                  >
                    Generate Matchups and Standings
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
        {showGamesComponent && schedule ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            {schedule.map((game, gameIndex) => {
              return (
                <div
                  key={Math.random() + Date.now()}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: 20,
                    border: "1px solid #00000015",
                    borderRadius: 7,
                    boxShadow: "0 5px 10px rgba(0,0,0,.2)",
                    margin: "0 5px",
                    padding: 10,
                    // minWidth: 500,
                  }}
                >
                  <span
                    style={{
                      fontWeight: "600",
                      fontSize: 22,
                      marginBottom: 10,
                      textAlign: "center",
                    }}
                  >
                    {game.name}
                  </span>
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      flexDirection: "column",
                    }}
                  >
                    {game.matchups.map((matchup, index) => {
                      return (
                        <div
                          key={Math.random() + Date.now()}
                          style={{
                            marginBottom: 5,
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            borderBottom: "1px solid #d2d2d280",
                            paddingBottom: 5,
                            alignItems: "center",
                          }}
                        >
                          <span
                            style={{
                              flex: 1,
                              textAlign: "center",
                              backgroundColor: matchup[0].isWinner
                                ? "#11c429"
                                : matchup[0].isLoser
                                ? "#d2d2d280"
                                : `${
                                    teamColors[
                                      teams.findIndex(
                                        (t) => t === matchup[0].name
                                      )
                                    ]
                                  }30`,
                              padding: "10px 3px",
                              borderRadius: 7,
                              cursor: "pointer",
                              opacity: matchup[0].isLoser ? 0.4 : 1,
                              fontSize: ".7em",
                              minWidth: 120,
                              fontWeight: "600",
                            }}
                            onClick={() =>
                              UpdateMatchOutcome(
                                matchup,
                                matchup[0],
                                0,
                                gameIndex,
                                index
                              )
                            }
                          >
                            {matchup[0].name}
                          </span>
                          <span
                            style={{
                              margin: "0 10px",
                              width: 30,
                              textAlign: "center",
                            }}
                          >
                            vs.
                          </span>
                          <span
                            style={{
                              flex: 1,
                              textAlign: "center",
                              backgroundColor: matchup[1].isWinner
                                ? "#11c429"
                                : matchup[1].isLoser
                                ? "#d2d2d280"
                                : `${
                                    teamColors[
                                      teams.findIndex(
                                        (t) => t === matchup[1].name
                                      )
                                    ]
                                  }30`,
                              padding: "10px 3px",
                              borderRadius: 7,
                              cursor: "pointer",
                              opacity: matchup[1].isLoser ? 0.4 : 1,
                              fontSize: ".7em",
                              minWidth: 120,
                              fontWeight: "600",
                            }}
                            onClick={() =>
                              UpdateMatchOutcome(
                                matchup,
                                matchup[1],
                                1,
                                gameIndex,
                                index
                              )
                            }
                          >
                            {matchup[1].name}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
      <div
        style={{
          position: "fixed",
          bottom: 25,
          right: 25,
          backgroundColor: "#fff",
          padding: 20,
          boxShadow: "0 5px 5px rgba(0,0,0,.5)",
          borderRadius: 5,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            // flexWrap: "wrap",
            marginBottom: 10,
            maxWidth: 400,
          }}
        >
          {["All", ...games].map((game, i) => {
            return (
              <button
                style={{
                  border: "1px solid #ccc",
                  borderRadius: 5,
                  padding: 5,
                  marginBottom: 5,
                  marginRight: 3,
                  cursor: "pointer",
                  fontSize: ".8em",
                  background: game === selectedGame ? "green" : "#fff",
                  color: game === selectedGame && "#fff",
                }}
                onClick={() => {
                  setSelectedGame(game);
                  GenerateStandings(
                    game === "All"
                      ? null
                      : schedule.filter((s) => s.name === game)
                  );
                }}
              >
                {game}
              </button>
            );
          })}
        </div>
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              marginBottom: 10,
            }}
          >
            <span style={{ width: 30 }}></span>
            <span
              style={{
                flex: 1,
                textAlign: "left",
                paddingRight: 15,
                fontWeight: "600",
              }}
            >
              Team
            </span>
            <span style={{ width: 30, textAlign: "center", fontWeight: "600" }}>
              W
            </span>
            <span style={{ width: 30, textAlign: "center", fontWeight: "600" }}>
              L
            </span>
            <span style={{ width: 30, textAlign: "center", fontWeight: "600" }}>
              GP
            </span>
          </div>
          {standings
            .sort((a, b) => b.wins - a.wins)
            .map((team, index) => {
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    marginBottom: 10,
                    borderRadius: 10,
                    paddingLeft: 5,
                    border: "1px solid #33333350",
                    background: `${
                      teamColors[teams.findIndex((t) => t === team.name)]
                    }30`,
                  }}
                >
                  <span style={{ width: 20 }}>{index + 1}.</span>
                  <span
                    style={{ flex: 1, textAlign: "left", paddingRight: 25 }}
                  >
                    {team.name}
                  </span>
                  <span style={{ width: 30, textAlign: "center" }}>
                    {team.wins}
                  </span>
                  <span style={{ width: 30, textAlign: "center" }}>
                    {team.losses}
                  </span>
                  <span style={{ width: 30, textAlign: "center" }}>
                    {team.gamesPlayed}
                  </span>
                </div>
              );
            })}
        </div>
        {showMenu ? (
          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
              flexDirection: " column",
            }}
          >
            <FaWindowClose
              style={{ color: "#333", cursor: "pointer" }}
              onClick={() => setShowMenu(!showMenu)}
            />
            <div
              style={{ cursor: "pointer", color: "#333" }}
              onClick={() => setShowBlindDraw(!showBlindDraw)}
            >
              {showBlindDraw ? "Hide Blind Draw" : "Show Blind Draw"}
            </div>
            <div
              style={{ cursor: "pointer", color: "#333" }}
              onClick={() => setShowGamesComponent(!showGamesComponent)}
            >
              {showGamesComponent ? "Hide Schedule" : "Show Schedule"}
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
              flexDirection: " column",
            }}
          >
            <FaHamburger
              style={{ color: "#333", cursor: "pointer" }}
              size={20}
              onClick={() => setShowMenu(!showMenu)}
            />
          </div>
        )}
      </div>
    </Layout>
  );
};

export default BeerOlympics;
